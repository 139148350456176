<template>
  <div>
    <title-bar :title="$t('navigations.resorts')"/>
    <div v-if="resorts" class="custom-table">
      <table-actions
        :actions="['search', 'addBtn', 'perPage']"
        :addBtnText="$t('messages.resortAdd')"
        :addBtnLink="$helper.getAddUrl('resort')"
        @onSearchInput="inputChange"
        @perPageChange="perPageChange"
        :defaultPerPage="perPage"
      />
      <table-lite
        :is-slot-mode="true"
        :is-loading="isLoading"
        :columns="columns"
        :rows="resorts"
        :sortable="{}"
        @is-finished="isLoading = false"
      >
        <template v-slot:name="data">
          <router-link :to="$helper.getEditUrl('resort', data.value.id)">{{
              data.value.name
            }}
          </router-link>
        </template>
        <template v-slot:status="data">
          <b-badge :variant="orderStatusClass(data.value.status)">{{
              data.value.status
            }}
          </b-badge>
        </template>
        <template v-slot:actions="data">
          <div class="just-buttons">
            <edit-btn :path="$helper.getEditUrl('resort', data.value.id)"/>
            <view-btn :pathUrl="$helper.getDetailUrl('resort', data.value.id)"/>
          </div>
        </template>
      </table-lite>
      <table-pagination
        v-model="currentPage"
        :total="pages"
        :per-page="perPage"
        @input="onChangePage"
      />
    </div>
  </div>
</template>

<script>
import {BBadge} from "bootstrap-vue-3";
import TableLite from "vue3-table-lite";
import TablePagination from "@/components/Table/TablePagination.vue";
import TableActions from "@/components/Table/TableActions.vue";

import ViewBtn from "@/components/Buttons/ViewBtn.vue";
import EditBtn from "@/components/Buttons/EditBtn.vue";
import TitleBar from "@/components/TitleBar.vue"

export default {
  components: {
    TableLite,
    TablePagination,
    TableActions,
    EditBtn,
    ViewBtn,
    BBadge,
    TitleBar
  },
  data() {
    return {
      search: "",
      isLoading: false,
      resorts: null,
      perPage: this.$store.state.defaultPerPage,
      currentPage: 1,
      pages: 0,
      columns: [
        {
          label: this.$t("forms.name"),
          field: "name",
          sortable: false,
        },
        {
          label: this.$t("forms.status"),
          field: "status",
          sortable: false,
          width: "120px",
        },
        {
          label: this.$t("forms.actions"),
          field: "actions",
          sortable: false,
          width: "120px",
        },
      ],
    };
  },
  created() {
    this.loadResorts();
  },
  methods: {
    loadResorts() {
      const params = {
        itemsPerPage: this.perPage,
        page: this.currentPage,
      };
      if (this.search && this.search.length) {
        params.search = this.search;
      }
      this.$Resorts.getCollection({params}, 'resort_list').then((response) => {
        this.pages = response.data["hydra:totalItems"];
        this.resorts = response.data["hydra:member"];
      });
    },
    onChangePage(page) {
      this.currentPage = page;
      this.loadResorts();
    },
    orderStatusClass(status) {
      let className = "inactive";
      if (status === "ACTIVE") {
        className = "active";
      }
      return className;
    },
    inputChange(value) {
      this.currentPage = 1;
      this.search = value;
      this.loadResorts();
    },
    perPageChange(num) {
      this.currentPage = 1;
      this.perPage = num;
      this.loadResorts();
    },
  },
};
</script>
